@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

html,
body {
  font-family: Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

.header-logo {
  width: 140px;
  height: 40px;
}

a.nav-link,
a.dropdown-item {
  color: white;
}

.primary-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(24 24 24 / var(--tw-bg-opacity));
}

.footer-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(20 20 20 / var(--tw-bg-opacity));
}

.review,
.ad {
  margin: 10px;
  text-decoration: none;
}

.review-body {
  height: 30vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.rounded-f {
  border-radius: 10px;
  overflow: hidden;
}

.gradient {
  background: #118df0;
}

.small-sprite {
  height: 13px;
  width: 13px;
}

.height-4 {
  height: 4px;
}

.padding-tb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.like-gradient {
  background: #111;
  background: linear-gradient(0deg, #111, rgba(81, 116, 197, 0));
}

.pagination {
  --tw-bg-opacity: 1;
  --bs-pagination-bg: rgb(24 24 24 / var(--tw-bg-opacity));
  --bs-pagination-disabled-bg: rgb(24 24 24 / var(--tw-bg-opacity));
  --bs-pagination-border-color: #ffffff00;
  --bs-pagination-disabled-border-color: #ffffff00;
  --bs-pagination-color: #ffffff;
}

.flags {
  height: 14px;
  width: 14px;
}

.town {
  font-size: 0.875rem;
}

.country {
  font-size: 14px;
}

.search-box {
  border: 0px solid #ced4da;
  color: white;
  background-color: unset;
}

.base-input-border {
  --tw-border-opacity: 1;
  border: 2px solid rgb(39 39 39 / var(--tw-border-opacity));
  border-radius: 10px;
}

.base-input-border:focus-within {
  border: 2px solid #e90f5d;
}

.base-input-border:focus-within > textarea {
  border-color: #e90f5d;
}

.base-input-border > span {
  background-color: rgb(20 20 20);
  border: none;
}

.base-input {
  color: white;
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
  border: unset;
}

.base-input:focus {
  color: white;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
  outline: 0;
  border: unset;
  box-shadow: unset;
}

.base-input::placeholder {
}

.sidebar-inner {
  padding-left: 0;
  padding-right: 0;
}

.search-box:focus {
  color: white;
  background-color: unset;
  border-color: unset;
  outline: 0;
  border-bottom: 1px solid #e90f5d;
  border-radius: 0;
  box-shadow: unset;
}

.footer {
  font-size: 12px;
}

.gray {
  --tw-text-opacity: 1;
  color: rgb(153 153 153 / var(--tw-text-opacity));
}

.icons {
  width: 1.5rem;
  height: 1.5rem;
}

svg {
  height: 1rem;
  width: 1rem;
}

.nav-link:hover > svg {
  --tw-text-opacity: 1;
  color: rgb(233 15 93 / var(--tw-text-opacity));
}

.nav-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
  color: white;
}

.nav-link.active > svg {
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(233 15 93 / var(--tw-text-opacity));
}

.nav-link.active > p {
  color: white;
}

.nav-link.active {
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity));
}

main {
  padding-top: 65px;
  @media (min-width: 1200px) {
    padding-top: 82px;
  }
}

.pink-background {
  background-color: #e90f5d;
}

.pink-color {
  color: #e90f5d;
}

.button-round {
  border-radius: 40px;
}

.button-round:hover {
  border-color: #cb0049;
  background-color: #cb0049;
}

.honor-first {
  background-color: rgb(252, 196, 23, 1);
}

.honor-second {
  background-color: rgb(206, 205, 210, 1);
}

.honor-third {
  background-color: rgb(247, 148, 41, 1);
}

.default-svg {
  height: 25px;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  transition: color 0.15s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.35s ease-in-out, box-shadow 0.2s ease-in-out;
}

.form-check-input:checked {
  background-color: #e90f5d;
  border-color: #e90f5d;
}

.form-check-input:focus {
  background-color: #e90f5d !important;
}

.form-check-input:hover {
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-select {
  &__control {
    min-height: 60px !important;
    background-color: rgb(20 20 20) !important;
    border-radius: 10px !important;
    border: none !important;

    &.focused {
      border-color: #e90f5d !important;
      box-shadow: 0 0 0 1px #e90f5d !important;
    }

    &.errored {
      border-color: rgb(239 68 68) !important;
      box-shadow: 0 0 0 1px rgb(239 68 68) !important;
    }
  }

  &__single-value {
    color: white !important;
    padding: 0 12px;
  }

  &__placeholder {
    padding: 0 12px;
  }

  &__menu {
    background-color: rgb(20 20 20) !important;
  }

  &__separator {
    display: none;
  }

  &__portal {
    z-index: 99999 !important;
  }

  &__option {
    color: white !important;

    &.focused {
      color: rgb(20 20 20) !important;
    }

    &.selected {
      background-color: #e90f5d !important;
    }
  }

  & input {
    margin-left: 12px !important;
    color: #999999 !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.dropdown-toggle {
  @apply hover:shadow-[0px_0px_3px_2px_#E90F5D];

  z-index: unset;
}

.yarl__portal {
  z-index: 99999 !important;
}
