/* PortalDialog.scss */

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 99999;
}

.dialog-overlay-enter {
  opacity: 0;
}

.dialog-overlay-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.dialog-overlay-exit {
  opacity: 1;
}

.dialog-overlay-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.dialog-content {
  z-index: 1;
}

.modal__close svg {
  pointer-events: none;
}
