@tailwind base;
@tailwind components;
@tailwind utilities;

@import './globals.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -webkit-margin-top-collapse: discard;
}

body {
  @apply relative;
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bg-third {
  --tw-bg-opacity: 0.95;
}

label > * {
  pointer-events: none;
}

.shadow-filter {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gradient {
  background: rgb(17, 141, 240);
}

.search-gradient {
  background: rgb(233, 15, 93);
  background: linear-gradient(0deg, rgba(233, 15, 93, 1) 0%, rgba(81, 116, 197, 0) 100%);
}

.link-gradient {
  background: linear-gradient(90deg, #e90f5d 0%, rgba(233, 15, 93, 0) 100%);
}

.like-gradient {
  background: rgb(17, 17, 17);
  background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(81, 116, 197, 0) 100%);
}

.hide-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-selected {
  @apply bg-secondary;
}

.nav-selected svg {
  @apply text-pink;
}

.nav-link {
  @apply relative flex h-full flex-col items-center justify-center gap-1.5 px-1 md:px-6;
}

.nav-link-active {
  @apply bg-secondary;
}

.nav-link-active > div {
  @apply block;
}

.nav-link:hover {
  @apply bg-secondary;
}

.nav-link svg {
  @apply h-4 w-4 text-white;
}

.link {
  @apply flex-grow-0 border-b-[1px] border-transparent text-sm text-pink;
}

.link:hover {
  @apply border-pink;
}

.nav-link span {
  @apply text-sm font-medium text-white;
}

.nav-link:hover > svg {
  @apply text-pink;
}

.nav-link-active:hover > svg {
  @apply text-white;
}

.pagination-link:nth-of-type(6) ~ .pagination-link {
  @apply flex-1 px-0;
}

.pagination-link {
  @apply flex h-full max-w-[55px] flex-1 items-center justify-center transition duration-150 ease-linear hover:bg-secondary;
}

.pagination-link span {
  @apply transform text-sm transition-transform duration-150 ease-linear lg:text-base;
}

.pagination-link:hover span {
  @apply -translate-y-0.5;
}

.pagination-link-truncated {
  @apply flex h-full max-w-[55px] flex-1 select-none items-center justify-center;
}

.pagination-chevron {
  @apply flex h-full w-10 shrink-0 items-center justify-center border-secondary transition duration-150 ease-linear hover:bg-secondary xl:w-14;
}

.pagination-chevron svg {
  @apply h-4 w-4 text-white;
}

.pagination-chevron-left {
  // @apply border-r-[1px];
  border-right-width: 1px;
}

.pagination-chevron-left svg {
  @apply rotate-180 transform transition duration-150 ease-linear;
}

.pagination-chevron-left:hover svg {
  @apply -translate-x-1;
}

.pagination-chevron-inactive svg {
  @apply text-stone-400;
}

.pagination-chevron-inactive:hover {
  @apply text-stone-400 bg-transparent;
}

.pagination-chevron-inactive:hover svg {
  @apply translate-x-0 text-stone-400;
}

.pagination-chevron-right {
  border-left-width: 1px;
}

.pagination-chevron-right svg {
  @apply transform transition duration-150 ease-linear;
}

.pagination-chevron-right:hover svg {
  @apply translate-x-1;
}

.pagination-link-selected {
  @apply bg-secondary font-black text-pink;
}

.pagination-link-selected:hover span {
  @apply -translate-y-0;
}

.accordion-selection {
  @apply flex h-14 items-center justify-between px-7 text-sm text-gray;
}

.radio-rating-container {
  @apply relative z-10 inline-flex h-[50px] flex-1 items-center justify-center text-sm text-stone-400;
}

.radio-rating-container:hover {
  @apply bg-third;
}

.radio-rating-container:hover > span {
  @apply text-white;
}

.radio-rating-container:first-child > div {
  @apply rounded-l-lg;
}

.radio-rating-container:first-child > div {
  border-left-width: 1px;
  border-color: #272727;
}

.radio-rating-container:last-child > div {
  @apply rounded-r-lg;
}

.radio-rating-container:last-child > div {
  border-right-width: 1px;
  border-color: #272727;
}

.radio-rating-container > div {
  @apply absolute top-0 left-0 -z-10 h-full w-full;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-color: #272727;
}

.radio-rating-container input {
  @apply invisible absolute top-0 left-0;
}

.radio-rating-container input:checked ~ span {
  @apply font-bold text-white;
}

.radio-rating-container input:checked ~ div {
  @apply bg-third;
  box-shadow: inset 0px 0px 3px 2px #e90f5d;
}

.radio-rating-container input:checked ~ div {
  @apply border-none;
}

.radio-container {
  @apply relative z-10 flex cursor-pointer items-center py-3 text-sm text-gray lg:py-5;
  padding-left: 40px;
}

.radio-container-pad {
  @apply py-2.5;
  padding-left: 27px;
}

.radio-container input {
  @apply transform opacity-0;
}

.radio-container .fake-radio {
  @apply absolute left-7 top-1/2 -translate-y-1/2 transform rounded-full border bg-transparent;
  border-color: #272727;
  height: 20px;
  width: 20px;
}

.radio-container-pad .fake-radio {
  @apply left-5 h-4 w-4;
}

.radio-container .fake-radio::before {
  @apply absolute bottom-[unset] h-0 w-0 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-pink transition duration-150 ease-linear;
  content: '';
  left: 50%;
  top: 50%;
}

.radio-container input:checked ~ .fake-radio::before {
  height: 70%;
  width: 70%;
}

.radio-container-pad input:checked ~ .fake-radio::before {
  height: 40%;
  width: 40%;
}

.radio-container input:checked ~ .fake-radio {
  @apply border-transparent;
  box-shadow: 0px 0px 3px 2px #e90f5d;
  left: 1.9rem;
  height: 16px;
  width: 16px;
}

.radio-container-pad input:checked ~ .fake-radio {
  left: 1.3rem;
  height: 14px;
  width: 14px;
}

.radio-container input:checked ~ span {
  @apply text-white;
}

.radio-container input:checked ~ div {
  @apply block;
}

.radio-container:hover {
  @apply text-white;
}

.radio-container:hover > div {
  @apply block;
}

.radio-container input:checked {
  @apply text-white;
}

.radio-container .total {
  @apply absolute right-7 top-1/2 -translate-y-1/2 transform;
}

.radio-container-pad .total {
  @apply absolute right-5 top-1/2 -translate-y-1/2 transform;
}

.checkbox-container {
  @apply relative py-3 pl-16 pr-7 text-sm text-stone-400;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  @apply absolute top-1/2 left-7 -translate-y-1/2 transform rounded-md bg-secondary;
  border-width: 1px;
  border-color: #272727;
  height: 24px;
  width: 24px;
}

.checkbox-container:hover > input ~ .checkmark {
  @apply bg-third;
}

.checkbox-container:hover > span {
  @apply text-white;
}

.checkbox-container input:checked ~ .checkmark {
  @apply border-transparent;
  box-shadow: 0px 0px 3px 2px #e90f5d;
}

.checkbox-container input:checked ~ span {
  @apply text-white;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.pricing-radio input:checked ~ div {
  @apply bg-secondary text-white;
}

.pricing-radio input:checked ~ div > .fake-radio > div {
  @apply h-4 w-4 border-transparent;
  box-shadow: 0px 0px 3px 2px #e90f5d;
}

.pricing-radio input:checked ~ div > .fake-radio > div > span {
  @apply block;
}

.checkbox-container .checkmark:after {
  @apply text-pink;
  left: 8px;
  top: 6px;
  width: 5px;
  height: 8px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ac-header {
  @apply relative flex h-14 items-center justify-between px-7 text-sm text-gray;
}

.ac-header-sidabar {
  @apply transition duration-150 ease-linear;
}

.ac-header-sidebar:hover {
  @apply bg-third;
}

.ac-header-sidebar:hover:after {
  @apply bg-primary text-white;
}

.ac-header-sidebar {
  @apply relative flex h-12 items-center justify-between px-5 text-sm text-white;
}

.ac-header-sidebar::after {
  @apply right-5 flex h-4 w-4 origin-center transform items-center justify-center rounded bg-third text-[#848484] transition duration-150 ease-linear;
  content: '+';
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
}

.ac-header::after {
  @apply right-7 h-2.5 w-2.5 origin-center transform transition duration-150 ease-linear;
  content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.20705 2.92969H1.79299C1.6006 2.92969 1.49318 3.13281 1.61232 3.27148L4.81935 6.99023C4.91115 7.09668 5.08791 7.09668 5.18068 6.99023L8.38771 3.27148C8.50685 3.13281 8.39943 2.92969 8.20705 2.92969Z' fill='%23999999'/%3E%3C/svg%3E%0A");
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
}

.ac .ac-panel {
  @apply invisible overflow-hidden;
  transition-property: height, visibility;
  transition-timing-function: ease;
}

.is-active .ac-header {
  @apply bg-third text-white;
}

.is-active .ac-panel {
  @apply visible;
}

.is-active .ac-panel-padding {
  @apply py-2.5;
}

.is-active .ac-header::after {
  @apply rotate-180;
}

.is-active .ac-header-sidebar::after {
  content: '-';
}

.mobile-nav-drawer {
  top: 64px !important;
  height: calc(100% - 64px) !important;
}

.mobile-nav-wrapper {
  @apply z-[99999] w-full overflow-auto max-h-full;
  height: 100%;
  max-width: 100vw;
  float: right;
}

.mobile-nav-items {
  @apply flex flex-col bg-secondary;
}

.mobile-nav-items .mobile-nav-header {
  @apply px-7 pb-2.5 text-xs text-stone-400;
}

.mobile-nav-items .mobile-nav-item {
  @apply inline-flex gap-2 border-third px-7 py-3 text-sm font-medium text-white lg:py-5;
  border-bottom-width: 1px;
}

.mobile-nav-items .mobile-nav-link {
  @apply inline-flex flex-1 items-center gap-2;
}

.mobile-nav-items .mobile-nav-item .mobile-nav-selected {
  @apply relative bg-third;
}

.mobile-nav-items .mobile-nav-item .mobile-nav-selected::after {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 10L4 12L10 18L20 8L18 6L10 14L6 10Z' fill='%23E90F5D'/%3E%3C/svg%3E%0A");
  @apply right-0 origin-center transform pr-7 pt-2 text-center transition duration-150 ease-linear;
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
}

.mobile-nav-items .mobile-nav-item-header {
  @apply border-transparent bg-primary text-stone-400;
}

.mobile-nav-items .mobile-nav-item-small {
  @apply py-4 text-sm font-normal;
}

.mobile-nav-items .mobile-nav-expand-link {
  @apply relative;
}

.mobile-nav-items .mobile-nav-expand > .mobile-nav-expand-link::after {
  content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.20705 2.92969H1.79299C1.6006 2.92969 1.49318 3.13281 1.61232 3.27148L4.81935 6.99023C4.91115 7.09668 5.08791 7.09668 5.18068 6.99023L8.38771 3.27148C8.50685 3.13281 8.39943 2.92969 8.20705 2.92969Z' fill='%23999999'/%3E%3C/svg%3E%0A");
  @apply right-0 origin-center -translate-y-1/2 -rotate-90 transform text-center transition duration-150 ease-linear;
  /* transform: translate(0, -50%); */
  position: absolute;
  top: 50%;
}

.mobile-nav-items .mobile-nav-expand-content {
  @apply z-50 h-full bg-secondary py-7;
  max-height: calc(100vh_-_5rem);
  min-height: calc(100vh_-_5rem);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(100%);
  transition: 0.3s;
  visibility: hidden;
}

.mobile-nav-items .active > .mobile-nav-expand-content {
  transform: translateX(0);
  visibility: visible;
}

.sepator {
  @apply w-full bg-third;
  height: 1px;
}

.btn-rounded {
  @apply flex w-full items-center justify-center rounded-full py-4 text-sm font-medium text-white;
  border-width: 1px;
  border-color: #272727;
}

.btn-rounded:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.form-control-input {
  @apply transition duration-150 ease-linear hover:placeholder:text-white;
}

.form-control-input:not(:placeholder-shown) ~ div > svg {
  @apply bg-third text-white;
}

.search-input input:not(:placeholder-shown) ~ svg {
  @apply text-white;
}

.form-control-input:focus {
  @apply bg-third text-white hover:placeholder:text-stone-400;
}

.form-control-input:focus ~ div > svg {
  @apply text-pink;
}

.form-control-input:focus:hover ~ div > svg {
  @apply text-pink;
}

.form-control-input:focus {
  @apply border-transparent;
  box-shadow: 0px 0px 3px 2px #e90f5d;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0e9b57;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0e9b57;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.user-meniu-link {
  @apply relative shrink-0 inline-flex w-full items-center justify-between py-3 px-7 font-medium text-stone-400 text-xs lg:text-base transition-all duration-150 ease-linear hover:bg-secondary hover:text-white;
}

.user-meniu-link > span {
  @apply h-8 inline-flex items-center text-sm;
}

.user-meniu-link > .hidden-bg {
  @apply link-gradient absolute top-0 left-0 hidden h-full w-[4px] transition-all duration-150 ease-linear;
}

.user-meniu-link-active {
  @apply bg-secondary text-white;
}

.user-meniu-link-active > .hidden-bg {
  @apply block;
}
